import React from 'react';
import { Link } from 'react-router-dom';
import InventarioIMG from '../../asset/Inventario.png';

// import { Container } from './styles';

function Card() {
  return <>
    <div className="card bg-light text-dark col-11 col-sm-11 col-md-5 col-lg-5 m-3" >
        <img src={InventarioIMG} className="card-img-top" alt="..."/>
        <div className="card-body">
            <h1 className="card-title">Por que fazer um inventário?</h1>
            <p className="card-text">
                – É através do inventário que é feita a regularização de bens imóveis e bens móveis de pessoas falecidas;<br/>
                – Possibilita a eventual venda dos bens deixados pelo falecido;<br/>
                – Liberação dos valores retidos em conta no nome do falecido;<br/>
                – Quanto antes for feito menos custo para o cliente.
            </p>
            
            <Link to='/inventario' className='btn btn-primary'>Saiba Mais</Link>
        </div>
    </div>
  </>;
}

export default Card;