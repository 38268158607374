import React from 'react';

// import { Container } from './styles';

function QuemSomos() {
  return <>
  <div className='container'>
    <div className='bg-light text-dark p-3 mt-2'>
      <h2>Quem Somos nós</h2>
      <p>
        Advogado: Luiz Bernardo de Souza Froner – OAB/RS 53.824<br/>
        Graduação: Universidade do Vale do Rio do Sinos – Unisinos em 1999.<br/>
        Áreas de atuação: Direito sucessório e direito de família.
      </p>
    </div>
    <div className='bg-light text-dark p-3 mt-2'>
      <h2>Nossa Missão</h2>
      <p>
        Ajudar pessoas a resolverem seus problemas legais de forma mais rápida e fácil possível.
      </p>
    </div>
    <div className='bg-light text-dark p-3 mt-2 mb-2'>
      <h2>Nossos Valores</h2>
      <p>
        Respeito à ética jurídica e a legalidade.
      </p>
    </div>
  </div>
  </>;
}

export default QuemSomos;