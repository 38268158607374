import React, { useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import InventariosRS from '../../asset/InventariosRS.png';

// import { Container } from './styles';



function Menu() {
//   document.getElementById("demo").innerHTML =
// "Page path is " + window.location.pathname;
  // const {pageName} = window.location.pathname();
  const [homeMenu, setHomeMenu] = useState('active');
  const [inventarioMenu, setInventarioMenu]= useState('active');
  const [divorcioMenu, setDivorcioMenu] = useState('active');
  const [quemSomosMenu, setQuemSomosMenu] = useState('active');

  // console.log(`${pageName}`);

  return <>
    <nav className=" container navbar navbar-expand-lg navbar-dark bg-dark">
  <div className="container-fluid">
    <Link className="navbar-brand" to='/'><img src={InventariosRS} height='75'></img></Link>    
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div className="navbar-nav">
        
        <Link className={`nav-link ${homeMenu}`} aria-current="page" to='/'>Home</Link> 
        <Link className={`nav-link ${inventarioMenu}`} to='/inventario'>Inventário</Link>
        <Link className={`nav-link ${divorcioMenu}`} to='/divorcio'>Divórcio</Link>
        <Link className={`nav-link ${quemSomosMenu}`} to='/quemsomos'>Quem Somos</Link>
        {/* <a class="nav-link disabled">Disabled</a> */}
      </div>
    </div>
  </div>
</nav>
  </>;
}

export default Menu;