import React from 'react';
import {Route, Routes} from "react-router-dom";
import Home from '../page/Home';
import Inventario from '../page/Inventario';
import Divorcio from '../page/Divorcio';
import QuemSomos from '../page/QuemSomos';

// import { Container } from './styles';

function RouterNavigation() {
  return <>
    <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/inventario' element={<Inventario/>}/>
        <Route path='/divorcio' element={<Divorcio/>}/>
        <Route path='/quemsomos' element={<QuemSomos/>}/>
    </Routes>
  </>;
}

export default RouterNavigation;