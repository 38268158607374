import React from 'react';
import CardDiv from '../../components/CardDiv';
import Card from '../../components/Card';

// import { Container } from './styles';

function Home() {
  return <>
  <div className='container p-0'>
    <div className='p-2 bg-dark text-white d-flex flex-column justify-content-center align-items-center mt-2'>
      <h1 className='m-2'>Inventários e Divórcios em Cartório</h1>
      <p className='m-2'>"A Lei Federal Nº 11.441/2007 possibilitou a realização de inventários e divórcios através de cartórios. A lei exige a atuação de um advogado para validade da escritura pública de inventário ou divórcio."</p>
    </div>
    <div className='bg-light text-dark m-2 p-3'>
      <h1>Inventários e Divórcios podem ser mais rápidos</h1>
      <p>Quando ouvimos falar em inventários e divórcios nos vem a mente processos demorados na justiça. Isso acontece em muitas vezes ao judicializar um inventário ou um divórcio ou quando existem disputas relacionadas a bens, guarda dos filhos e outros motivos, podendo arrastar o processo por anos.</p>
      <p>Porém, Inventários e divórcios feitos em cartório podem ser feitos em menor tempo, podendo ser finalizados em poucos meses, dependendo de acordo entre as partes e uma situação regular dos bens.</p>
      
    </div>
    <div className='container d-flex justify-content-around flex-wrap'>
      <Card/>
      <CardDiv/>
      
      
    </div>
  </div>

  </>;
}

export default Home;