import React from 'react';
import { Link } from 'react-router-dom';
import DivorcioIMG from '../../asset/divorcio.png';

// import { Container } from './styles';

function CardDiv() {
  return <>
    <div className="card bg-light text-dark col-11 col-sm-11 col-md-5 col-lg-5 m-3" >
        <img src={DivorcioIMG} className="card-img-top" alt="..."/>
        <div className="card-body">
            <h1 className="card-title">Por que fazer um divórcio?</h1>
            <p className="card-text">
              – Para regularizar o estado civil;<br/>
              – Para efetuar a partilha do patrimônio;<br/>
              – Para estabelecer pensão alimentícia;<br/>
              – Para evitar problemas com empréstimos, financiamentos ou aquisições;<br/>
              – Para alterar o nome.
            </p>
            
            <Link to='/divorcio' className='btn btn-primary'>Saiba Mais</Link>
        </div>
    </div>
  </>;
}

export default CardDiv;