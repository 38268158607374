import React from 'react';
import Whatsapp from '../../asset/whatsappFaleConosco.png';

// import { Container } from './styles';

function Inventario() {
  return <>
    <div className='container'>
      <div className='bg-light text-dark p-3 mt-2'>
        <h1>Como posso fazer um Inventário?</h1>
        <p>
          Um inventário é um processo importante e necessário para regularizar os bens de uma pessoa após o óbito.<br/>
          Os Inventários podem ser judiciais, que são caros e demorados ou podem ser feitos em Cartório de modo mais rápido e bem mais baratos que os feito judicialmente.
          Para fazer um Inventário em Cartório é necessário existir acordo entre os herdeiros
        </p>
        <h2>Requisitos para um Inventário em Cartório</h2>
        <p>
          – acordo entre os herdeiros;<br/>
          – não existência de herdeiro menor de idade ou incapaz;<br/>
          – não existência de testamento.<br/> 
        </p>
        <p>
          Obs. Quando não forem atendidos os requisitos acima o inventário será judicial.
        </p>
        <h2>Prazo para iniciar um Inventário</h2>
        <p>
          O prazo para abrir um inventário é de 60 (sessenta) dias a partir do óbito, conforme artigo 983 do Código de Processo Civil.
        </p>
        <p>
          "Art. 983. O processo de inventário e partilha deve ser aberto dentro de 60 (sessenta) dias a contar da abertura da sucessão, ultimando-se nos 12 (doze) meses subseqüentes, podendo o juiz prorrogar tais prazos, de ofício ou a requerimento de parte."
        </p>
        <p>
          Apesar do prazo legal, não há impedimento que o Inventário seja realizado em momento posterior.
        </p>
        <h2>Iniciando um processo de Inventário</h2>
        <p>
          Para abrir o processo de inventário em cartório, o interessado, em primeiro lugar, deverá consultar e contratar um advogado, já que o mesmo é indispensável para assistência das partes e legalidade dos atos.
        </p>
        <p>
          Agende um atendimento para tirarmos duvidas e esclarecimentos.
        </p>
        <a href='https://wa.me/5551997671190' target='_blank'><img src={Whatsapp} height='75'></img></a>
      </div>
      <div className='bg-dark text-white p-3 mt-2 mb-2'>
        <h1>Dúvidas sobre Inventário</h1>
        <h2>O que é um inventário?</h2>
        <p>
          O inventário é o meio legal pelo qual os bens deixados (herança) por uma pessoa falecida são transferidos para os seus herdeiros.
        </p>
        <h2>Por que fazer um inventário?</h2>
        <p>
          Para regularizar a situação dos bens deixados pelo falecido; para possibilitar a venda de bens imóveis (terrenos e casas) e móveis (veículos); para liberar saldos bancários; saldar dívidas da pessoa falecida.
        </p>
        <h2>Existe um prazo legal para se iniciar um inventário?</h2>
        <p>
          Sim. O artigo 983 do Código de Processo Civil estabelece o prazo de 60 (sessenta) dias a contar do óbito para a abertura do inventário.
        </p>
        <h2>Um inventário pode ser realizado em cartório?</h2>
        <p>
          Sim. Para ser realizado em cartório são necessários três requisitos básicos: acordo entre os herdeiros; inexistência de herdeiros menores ou incapazes; inexistência de testamento.
        </p>
        <h2>Quando um inventário será obrigatoriamente judicial?</h2>
        <p>
          Toda vez que não houver acordo entre os herdeiros; quando existirem herdeiros menores de idade ou incapazes; quando o autor da herança (falecido) deixar testamento.
        </p>
        <h2>Qual a vantagem do inventário em cartório?</h2>
        <p>
          A principal vantagem é a celeridade (em média 60 dias).<br></br>

          O custo, em regra, também fica menor se comparado com o processo judicial.
        </p>
        <h2>Posso vender um imóvel que tem como proprietário ou herdeiro um menor de idade ou incapaz civilmente?</h2>
        <p>
          Para venda de imóvel pertencente a menor ou incapaz é preciso da autorização de um juiz através de um processo de alvará judicial, nos termos do art. 1.691 do Código Civil:
        </p>
        <p>
         "Art. 1.691. Não podem os pais alienar, ou gravar de ônus real os imóveis dos filhos, nem contrair, em nome deles, obrigações que ultrapassem os limites da simples administração, salvo por necessidade ou evidente interesse da prole, mediante prévia autorização do juiz."
        </p>

      </div>
    </div>
  </>;
}

export default Inventario;