import React from 'react';
import Whatsapp from '../../asset/whatsappFaleConosco.png';

// import { Container } from './styles';

function Divorcio() {
  return <>
    <div className='container'>
      <div className='bg-light text-dark p-3 mt-2'>
        <h1>Como posso fazer um divórcio</h1>
        <p>
          Um divórcio é um processo necessário para regularizar a situação legal de um casal após a separação, pois define situação financeira, de partilha dos bens e guarda dos filhos, quando houverem.<br/>
          Os Divórcios podem ser judiciais, que são demorados ou podem ser feitos em Cartório de modo mais rápido que os feito judicialmente.
          Para fazer um Divórcio em Cartório é necessário existir acordo entre as partes e não haverem filhos menores de idade ou incapazes.
        </p>
        <h2>Requisitos para um Divórcio em Cartório</h2>
        <p>
          – acordo entre os cônjuges;<br/>
          – não existência de filho menor de idade ou incapaz;<br/>
        </p>
        <p>
          Obs. Quando não forem atendidos os requisitos acima o divórcio será judicial.
        </p>
        <h2>Prazo para iniciar um Divórcio</h2>
        <p>
          Não existe prazo previsto em lei.
        </p>
        <h2>Iniciando um processo de Divórcio em Cartório</h2>
        <p>
          Para abrir o processo de divórcio em cartório, os interessados, em primeiro lugar, deverão consultar e contratar um advogado, já que o mesmo é indispensável para assistência das partes e legalidade dos ato.

        </p>
        <p>
        Agende um atendimento para tirarmos duvidas e esclarecimentos.
        </p>
        <a href='https://wa.me/5551997671190' target='_blank'><img src={Whatsapp} height='75'></img></a>
      </div>
      <div className='bg-dark text-white p-3 mt-2 mb-2'>
        <h1>Dúvidas sobre fazer um Divórcio</h1>
        <h2>O que é um divórcio?</h2>
        <p>
          Divórcio é o procedimento previsto em lei para a dissolução definitiva da sociedade conjugal (casamento civil).
        </p>
        <h2>Por que legalizar um divórcio?</h2>
        <p>
          Para atualizar o estado civil; para efetuar a divisão de bens; para fixar alimentos; para não ter dificuldades na tomada de empréstimos, financiamentos ou aquisições; para alterar o nome.
        </p>
        <h2>Um divórcio pode ser realizado em cartório?</h2>
        <p>
          Sim. Para ser realizado em cartório existem dois requisitos básicos: consenso entre o casal em divórcio; não existência de filhos menores ou incapazes.
        </p>
        <h2>Quando um divórcio será obrigatoriamente judicial?</h2>
        <p>
          Toda vez que não houver acordo entre o casal; quando existirem filhos menores de idade ou incapazes;
        </p>
        <h2>Qual a vantagem do divórcio em cartório?</h2>
        <p>
          A principal vantagem é a celeridade (em média 30 dias).<br></br>

          O custo, em regra, também fica menor se comparado com o processo judicial.
        </p>
      </div>
    </div>
  </>;
}

export default Divorcio;