import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import {BrowserRouter, useParams} from 'react-router-dom'
import RouterNavigation from "./router";
import Menu from '../src/components/Menu';
import Footer from '../src/components/Footer';


function App() {
  const {pageName} = useParams();
  console.log(pageName);
  return (
    <BrowserRouter>
      <Menu/>
      <RouterNavigation/>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
