import React from 'react';
import Whatsapp from '../../asset/whatsappFaleConosco.png';

// import { Container } from './styles';

function Footer() {
  return <>
  <div className='container bg-dark text-white d-flex justify-content-between align-items-center'>
    <div className='m-2'>
      Entre em contato! <br></br>
      E-mail: <a href='mailto:contato@inventariosrs.com.br?' target='_blank'>contato@inventariosrs.com.br</a>
    </div>
    <div className='m-2'>
      <a href='https://wa.me/5551997671190'  target='_blank'><img src={Whatsapp} height='75'></img></a>
    </div>
    
  </div>
  
  </>;
}

export default Footer;